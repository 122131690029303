import React from 'react'
import PropTypes from 'prop-types'
import SEO from '../components/Seo'

const SalesTool = ({ pageContext: { data } }) => {
  const embedURL = new URL(data.figmaEmbedUrl)

  const params = embedURL.searchParams

  const sourceURL = new URL(params.get('url'))

  // ensure hiding Figma UI and scaling down of the prototype
  sourceURL.searchParams.append('scaling', 'scale-down-width')
  sourceURL.searchParams.append('hide-ui', 1)

  params.set('url', sourceURL)

  return (
    <>
      <SEO
        title="Your Innential Path"
        meta={[{ name: 'robots', content: 'noindex' }]} // PREVENT INDEXING THE PAGE
      />
      <iframe
        style={{
          width: 'calc(100vw - 10px)',
          height: 'calc(100vh - 10px)',
        }}
        // width="800"
        // height="450"
        title={data.companyName}
        src={embedURL}
        allowFullScreen
      />
    </>
  )
}

SalesTool.propTypes = {
  pageContext: PropTypes.shape({
    data: PropTypes.shape({
      companyName: PropTypes.string.isRequired,
      figmaEmbedUrl: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default SalesTool
